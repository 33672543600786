/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import { FormattedMessage, createIntl, createIntlCache } from 'react-intl';

import IntlMessageFormat from 'intl-messageformat';
import englishMessages from './translations/en.json';
import spanishMessages from './translations/es.json';

let language = 'es';
if (typeof window !== 'undefined') {
  language = window.sessionStorage.getItem('sessionLanguage');
}

let messages = spanishMessages;
switch (language) {
  case 'en':
    messages = englishMessages;
    break;

  case 'es':
    messages = spanishMessages;
    break;
}

const cache = createIntlCache();
const intl = createIntl({ locale: language, messages }, cache);
const formattedMessage = id => new IntlMessageFormat(intl.formatMessage({ id })).format({});

export const filters = [
  {
    id: 'dates',
    label: formattedMessage('Filters.dates'),
    type: 'BookingDateRangeFilter',
    group: 'primary',
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'area_c',
    label: formattedMessage('Filters.area_c'),
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_area_c'],
    config: {
      options: [
        { key: 'area_c1', label: formattedMessage('Filters.area_c1') },
        { key: 'area_c2', label: formattedMessage('Filters.area_c2') },
        { key: 'area_c3', label: formattedMessage('Filters.area_c3') },
        { key: 'area_c4', label: formattedMessage('Filters.area_c4') },
        { key: 'area_c5', label: formattedMessage('Filters.area_c5') },
        { key: 'area_c6', label: formattedMessage('Filters.area_c6') },
        { key: 'area_c7', label: formattedMessage('Filters.area_c7') },
        { key: 'area_c8', label: formattedMessage('Filters.area_c8') },
        { key: 'area_c9', label: formattedMessage('Filters.area_c9') },
        { key: 'area_c10', label: formattedMessage('Filters.area_c10') },
        { key: 'area_c11', label: formattedMessage('Filters.area_c11') },
        { key: 'area_c12', label: formattedMessage('Filters.area_c12') },
        { key: 'area_c13', label: formattedMessage('Filters.area_c13') },
        { key: 'area_c14', label: formattedMessage('Filters.area_c14') },
        { key: 'area_c15', label: formattedMessage('Filters.area_c15') },
      ],
    },
  },
  {
    id: 'price',
    label: formattedMessage('Filters.price'),
    type: 'PriceFilter',
    group: 'secondary',
    queryParamNames: ['price'],
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'volumen',
    label: formattedMessage('Filters.volumen'),
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_volumen'],
    config: {
      options: [
        { key: 'xs', label: formattedMessage('Filters.volumen_xs') },
        { key: 's', label: formattedMessage('Filters.volumen_s') },
        { key: 'm', label: formattedMessage('Filters.volumen_m') },
        { key: 'l', label: formattedMessage('Filters.volumen_l') },
        { key: 'xl', label: formattedMessage('Filters.volumen_xl') },
        { key: 'xxl', label: formattedMessage('Filters.volumen_xxl') },
      ],
    },
  },
  {
    id: 'actividad_c',
    label: formattedMessage('Filters.actividad_c'),
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_actividad_c'],
    config: {
      options: [
        { key: 'actividad_c1', label: formattedMessage('Filters.actividad_c1') },
        { key: 'actividad_c2', label: formattedMessage('Filters.actividad_c2') },
        { key: 'actividad_c3', label: formattedMessage('Filters.actividad_c3') },
        { key: 'actividad_c4', label: formattedMessage('Filters.actividad_c4') },
        { key: 'actividad_c5', label: formattedMessage('Filters.actividad_c5') },
        { key: 'actividad_c6', label: formattedMessage('Filters.actividad_c6') },
        { key: 'actividad_c7', label: formattedMessage('Filters.actividad_c7') },
        { key: 'actividad_c8', label: formattedMessage('Filters.actividad_c8') },
        { key: 'actividad_c9', label: formattedMessage('Filters.actividad_c9') },
        { key: 'actividad_c10', label: formattedMessage('Filters.actividad_c10') },
        { key: 'actividad_c11', label: formattedMessage('Filters.actividad_c11') },
        { key: 'actividad_c12', label: formattedMessage('Filters.actividad_c12') },
        { key: 'actividad_c13', label: formattedMessage('Filters.actividad_c13') },
        { key: 'actividad_c14', label: formattedMessage('Filters.actividad_c14') },
        { key: 'actividad_c15', label: formattedMessage('Filters.actividad_c15') },
        { key: 'actividad_c16', label: formattedMessage('Filters.actividad_c16') },
        { key: 'actividad_c17', label: formattedMessage('Filters.actividad_c17') },
        { key: 'actividad_c18', label: formattedMessage('Filters.actividad_c18') },
      ],
    },
  },
  {
    id: 'denominacion',
    label: formattedMessage('Filters.denominacion'),
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_denominacion'],
    config: {
      options: [
        { key: 'denominacion_1', label: formattedMessage('Filters.denominacion_1') },
        { key: 'denominacion_2', label: formattedMessage('Filters.denominacion_2') },
        { key: 'denominacion_3', label: formattedMessage('Filters.denominacion_3') },
        { key: 'denominacion_4', label: formattedMessage('Filters.denominacion_4') },
        { key: 'denominacion_5', label: formattedMessage('Filters.denominacion_5') },
        { key: 'denominacion_6', label: formattedMessage('Filters.denominacion_6') },
        { key: 'denominacion_7', label: formattedMessage('Filters.denominacion_7') },
        { key: 'denominacion_8', label: formattedMessage('Filters.denominacion_8') },
        { key: 'denominacion_9', label: formattedMessage('Filters.denominacion_9') },
        { key: 'denominacion_10', label: formattedMessage('Filters.denominacion_10') },
        { key: 'denominacion_11', label: formattedMessage('Filters.denominacion_11') },
      ],
    },
  },
  {
    id: 'orientacion',
    label: formattedMessage('Filters.orientacion'),
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_orientacion'],
    config: {
      options: [
        { key: 'orientacion_1', label: formattedMessage('Filters.orientacion_1') },
        { key: 'orientacion_2', label: formattedMessage('Filters.orientacion_2') },
        { key: 'orientacion_3', label: formattedMessage('Filters.orientacion_3') },
        { key: 'orientacion_4', label: formattedMessage('Filters.orientacion_4') },
        { key: 'orientacion_5', label: formattedMessage('Filters.orientacion_5') },
      ],
    },
  },
  {
    id: 'altura',
    label: formattedMessage('Filters.altura'),
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_altura'],
    config: {
      options: [
        { key: 'true', label: formattedMessage('Filters.altura_true') },
        { key: 'false', label: formattedMessage('Filters.altura_false') },
      ],
    },
  },
  {
    id: 'amenities_uupo',
    label: formattedMessage('Filters.amenities_uupo'),
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_amenities_uupo'],
    config: {
      options: [
        { key: 'amenities_uupo1', label: formattedMessage('Filters.amenities_uupo1') },
        { key: 'amenities_uupo2', label: formattedMessage('Filters.amenities_uupo2') },
        { key: 'amenities_uupo3', label: formattedMessage('Filters.amenities_uupo3') },
        { key: 'amenities_uupo4', label: formattedMessage('Filters.amenities_uupo4') },
        { key: 'amenities_uupo5', label: formattedMessage('Filters.amenities_uupo5') },
        { key: 'amenities_uupo6', label: formattedMessage('Filters.amenities_uupo6') },
        { key: 'amenities_uupo7', label: formattedMessage('Filters.amenities_uupo7') },
        { key: 'amenities_uupo8', label: formattedMessage('Filters.amenities_uupo8') },
        { key: 'amenities_uupo9', label: formattedMessage('Filters.amenities_uupo9') },
        { key: 'amenities_uupo10', label: formattedMessage('Filters.amenities_uupo10') },
        { key: 'amenities_uupo11', label: formattedMessage('Filters.amenities_uupo11') },
        { key: 'amenities_uupo12', label: formattedMessage('Filters.amenities_uupo12') },
        { key: 'amenities_uupo13', label: formattedMessage('Filters.amenities_uupo13') },
        { key: 'amenities_uupo14', label: formattedMessage('Filters.amenities_uupo14') },
        { key: 'amenities_uupo15', label: formattedMessage('Filters.amenities_uupo15') },
        { key: 'amenities_uupo16', label: formattedMessage('Filters.amenities_uupo16') },
        { key: 'amenities_uupo17', label: formattedMessage('Filters.amenities_uupo17') },
      ],
    },
  },
  {
    id: 'amenities_pop',
    label: formattedMessage('Filters.amenities_pop'),
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_amenities_pop'],
    config: {
      options: [
        { key: 'amenities_pop1', label: formattedMessage('Filters.amenities_pop1') },
        { key: 'amenities_pop2', label: formattedMessage('Filters.amenities_pop2') },
        { key: 'amenities_pop3', label: formattedMessage('Filters.amenities_pop3') },
        { key: 'amenities_pop4', label: formattedMessage('Filters.amenities_pop4') },
        { key: 'amenities_pop5', label: formattedMessage('Filters.amenities_pop5') },
        { key: 'amenities_pop6', label: formattedMessage('Filters.amenities_pop6') },
        { key: 'amenities_pop7', label: formattedMessage('Filters.amenities_pop7') },
        { key: 'amenities_pop8', label: formattedMessage('Filters.amenities_pop8') },
        { key: 'amenities_pop9', label: formattedMessage('Filters.amenities_pop9') },
        { key: 'amenities_pop10', label: formattedMessage('Filters.amenities_pop10') },
        { key: 'amenities_pop11', label: formattedMessage('Filters.amenities_pop11') },
        { key: 'amenities_pop12', label: formattedMessage('Filters.amenities_pop12') },
        { key: 'amenities_pop13', label: formattedMessage('Filters.amenities_pop13') },
      ],
    },
  },
  {
    id: 'categoria',
    label: formattedMessage('Filters.categoria'),
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_categoria'],
    config: {
      options: [
        { key: 'categoria1', label: formattedMessage('Filters.categoria1') },
        { key: 'categoria2', label: formattedMessage('Filters.categoria2') },
        { key: 'categoria3', label: formattedMessage('Filters.categoria3') },
        { key: 'categoria4', label: formattedMessage('Filters.categoria4') },
        { key: 'categoria5', label: formattedMessage('Filters.categoria5') },
        { key: 'categoria6', label: formattedMessage('Filters.categoria6') },
        { key: 'categoria7', label: formattedMessage('Filters.categoria7') },
        { key: 'categoria8', label: formattedMessage('Filters.categoria8') },
        { key: 'categoria9', label: formattedMessage('Filters.categoria9') },
        { key: 'categoria10', label: formattedMessage('Filters.categoria10') },
        { key: 'categoria11', label: formattedMessage('Filters.categoria11') },
        { key: 'categoria12', label: formattedMessage('Filters.categoria12') },
        { key: 'categoria13', label: formattedMessage('Filters.categoria13') },
        { key: 'categoria14', label: formattedMessage('Filters.categoria14') },
        { key: 'categoria15', label: formattedMessage('Filters.categoria15') },
        { key: 'categoria16', label: formattedMessage('Filters.categoria16') },
        { key: 'categoria17', label: formattedMessage('Filters.categoria17') },
      ],
    },
  },
  // {
  //   id: 'keyword',
  //   label: formattedMessage('Filters.keyword'),
  //   type: 'KeywordFilter',
  //   group: 'secondary',
  //   queryParamNames: ['keywords'],
  //   config: {},
  // },
];




export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: formattedMessage('Sort.createdAt') },
    { key: '-createdAt', label: formattedMessage('Sort.-createdAt') },
    { key: '-price', label: formattedMessage('Sort.-price') },
    { key: 'price', label: formattedMessage('Sort.price') },
    { key: 'relevance', label: formattedMessage('Sort.relevance'), longlabel: formattedMessage('Sort.relevanceLong') },
  ],
};

