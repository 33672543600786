import React, { Component, useEffect, useState } from 'react';
import classNames from 'classnames';
import img1 from '../../assets/se-uuper-rounded.png';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import css from './SectionOneUuper.module.css';

const SectionOneUuper = props => {

  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, latestListings } = props;
  const intl = useIntl();


  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className)

  return (
    <>
      <div className={`${css.uuperContent} ${classes}`}>
        <div className={css.topButtonContainer}>
          <NamedLink name={intl.formatMessage({ id: "SectionOneUuper.LandingUuper" })} className={css.ctaButtonDark}>
            <FormattedMessage id="SectionOneUuper.ctaButton" />
          </NamedLink>
        </div>
        <div className={css.containerUuper}>
          <div className={css.contentImgUuper}>
            <div>
              <img src={img1} className={css.image} />
            </div>
          </div>
          <div className={css.contentTextUuper}>
            {/* <div className={css.titleContainer}> */}
            {/* <span className={classNames(css.titleOneUuper, { [css.titleOneUuperFEDelay]: mounted })}>
                <FormattedMessage id="SectionOneUuper.title" />
              </span> */}
            <h2 className={classNames(css.textOneUuper, { [css.textOneUuperFEDelay]: mounted })}>
              <FormattedMessage id="SectionOneUuper.subtitle1" />
              <br />
              <br />
              <strong>
                <FormattedMessage id="SectionOneUuper.subtitle2" />
              </strong>
            </h2>
            {/* <strong className={css.subtitleOneUuperStrong}>
                <FormattedMessage id="SectionOneUuper.subtitle2" />
              </strong> */}
            {/* </div> */}
            <div className={classNames(css.textOneUuper, { [css.textOneUuperFEDelay]: mounted })}>
              <FormattedMessage id="SectionOneUuper.text1" />
            </div>
            <div className={classNames(css.textOneUuper, { [css.textOneUuperFEDelay]: mounted })}>
              <FormattedMessage id="SectionOneUuper.text2" />
            </div>
            <div className={classNames(css.textOneUuper, { [css.textOneUuperFEDelay]: mounted })}>
              <strong><FormattedMessage id="SectionOneUuper.text3.1" /></strong>
              <FormattedMessage id="SectionOneUuper.text3.2" />
            </div>
            <div className={classNames(css.textOneUuper, { [css.textOneUuperFEDelay]: mounted })}>
              <FormattedMessage id="SectionOneUuper.text4" />
              {/* <strong><FormattedMessage id="SectionOneUuper.text4.1" /></strong> */}
              {/* <FormattedMessage id="SectionOneUuper.text4.2" /> */}
            </div>
            {/* <div className={classNames(css.textOneUuper, { [css.textOneUuperFEDelay]: mounted })}>
              <FormattedMessage id="SectionOneUuper.text5" />
            </div> */}
            {/* <div className={classNames(css.textOneUuper, { [css.textOneUuperFEDelay]: mounted })}>
              <FormattedMessage id="SectionOneUuper.text6" />
            </div> */}
            <div className={css.contentButton}>
              <h3 style={{ margin: 0 }}>
                <NamedLink
                  name="SearchPage"
                  to={{
                    search:
                      '',
                  }}
                  className={classNames(css.heroUuperButton, { [css.heroUuperButtonFEDelay]: mounted })}
                >
                  <FormattedMessage id="SectionOneUuper.browseButton" />
                </NamedLink>
              </h3>
            </div>
          </div>
        </div>
      </div >
    </>
  )
};

export default SectionOneUuper;